.page {
    overflow-x: hidden;
    position: relative;
}

.contentContainer {
    color: white;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%)
}

.contentSubcontainers {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

ul {
    list-style: none;
    margin-left: 0;
    margin-top: 0;
    padding-left: 0;
}

li {
    padding-left: 1em;
    text-indent: -1em;
  }

li:before {
    content: "▸";
    padding-right: 3px;
  }

h3 {
    font-size: 26px;
    margin-bottom: 9px;
}

h2 {
    font-size: 30px;
    margin-bottom: 19px;
}

a {
    color:rgb(251, 202, 244);
    text-decoration: none;
}

.formMetadata {
    display: none;
}

input {
    font-size: 20px;
    background: none;
    border: none;
    border-bottom: 2px solid white;
    color: white;
    outline: none;
    transition: all .3s linear;
}

input:hover {
    border-bottom: 2px solid rgb(254, 215, 249);
}

input:focus {
    border-bottom: 2px solid rgb(251, 202, 244);
}

label {
    font-style: italic;
    color: rgba(255, 255, 255, 0.46);

}

.formRow {
    display: flex; 
    justify-content: space-between;
    margin-bottom: 10px;
}

.formRowGroup {
    margin-bottom: 30px;
}

.footerContainer {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.navigationContainerMobile {
    color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.navButtonMobile {
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 30px;
    left: 30px;
    border: none;
}

.navButtonMobile:hover {
    cursor: pointer;
}

.mobileMenu {
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 0px;
}

.navLinksMobile {
    margin: 20px;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.navigationContainer {
    color: white;
    position: absolute;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    top: 10px;
    left: 50vw;
    transform: translateX(-50%);
    z-index: 2;
}

.navButton {
    color: white;
    border: none;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    transition: all 0.2s;
    background-color: rgba(0, 0, 0, 0);
    margin-left: 5px;
    margin-right: 5px;
    font-size: 20px;
}
  
.navButton:hover {
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
}

.navButtonHome {
    color: white;
    border: none;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    transition: all 0.2s;
    background-color: rgba(0, 0, 0, 0);
    margin-left: 5px;
    margin-right: 5px;
    font-size: 20px;
}
  
.navButtonHome:hover {
    cursor: pointer;
}

@media (max-width: 500px) {
    .headerTitle {
        top: 100px; 
    }

    .navigationContainer {
        visibility: collapse;
    }

    .navigationContainerMobile {
        visibility: visible;
    }

    .FooterDots {
        visibility: collapse;
    }

    .footerDate {
        visibility: collapse;
        width: 0px;
    }
}

@media (min-width: 500px) {
    .headerTitle {
        top: 150px; 
    }

    .navigationContainer {
        visibility: visible;
    }

    .navigationContainerMobile {
        visibility: collapse;
    }

    .FooterDots {
        visibility: visible;
    }

    .footerDate {
        visibility: visible;
        width: auto;
    }
}

@media (max-width: 1200px) {
    .headerText {
        color: white;
        font-size: 70px;
        margin: 0;
        font-family: "HeaderFont";
        font-weight: normal;
        line-height: 70px;
    }

    .headerTitle {
        position: absolute; 
        left: 50%; 
        width: 90%;
        text-align: center;
        transform: translateX(-50%); 
        z-index: 1;
    }

    .contentContainer {
        width: 90%;
    }
}

@media (min-width: 1200px) {
    .headerText {
        color: white;
        font-size: 90px;
        margin: 0;
        font-family: "HeaderFont";
        font-weight: normal;
        line-height: 90px;
    }

    .headerTitle {
        position: absolute; 
        left: 50vw; 
        width: 1024px; 
        transform: translateX(-50%); 
        z-index: 1;
    }

    .contentContainer {
        width: 1024px;
    }
}

@media (max-width: 900px) {
    .flexCollapse {
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .contentSubcontainers {
        flex-direction: column;
    }

    .contentSubcontainer {
        width: 100%;
    }
}

@media (min-width: 900px) {
    .flexCollapse {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .contentSubcontainers {
        flex-direction: row;
    }

    .contentSubcontainer {
        width: 48%;
    }
}