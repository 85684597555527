@font-face {
  font-family: "HeaderFont";
  src:url(./recourses/fonts/clockwise-freefont/Clockwise-FreeFont/clockwise_light.otf) format("opentype");
}

/* disable opera video pop out button */
#detach-button-host { 
  display: none !important;
}

.home {
  overflow-x: hidden;
  position: relative;
}

.buttonLinkDiv {
  color: white; 
  border: 1px solid white; 
  border-radius: 9px 9px 9px 0;
  font-size: 20px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0);
}

.buttonLinkDiv:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

.tinyImage {
  transition: all linear .2s;
  margin-top: auto 0;
  width: 25px;
  height: 25px;
}

.tinyButton {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.tinyImage:hover {
  width: 30px;
  height: 30px;
}

p {
  margin: 0;
  margin-bottom: 30px;
  font-size: 20px;
  color: white;

}

.linkBar {
  display: flex;
}

@media (max-width: 1100px) {
  h1 {
    color: white;
    font-size: 55px;
    margin: 0;
    font-family: "HeaderFont";
    font-weight: normal;
    line-height: 55px;
  }

  .linkBar {
    position: absolute;
    display: block;
    top: 50%;
    left: 10px;
    z-index: 1;
    transform: translateY(-50%)
  }
}

@media (min-width: 1100px) {
  h1 {
    color: white;
    font-size: 90px;
    margin: 0;
    font-family: "HeaderFont";
    font-weight: normal;
    line-height: 90px;
  }

  .linkBar {
    position: absolute;
    bottom: 15px;
    margin: 0 auto;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
  } 
}

@media (max-width: 700px) {
  
  .content {
    position: absolute; 
    left: 50%; 
    bottom: 32%;
    transform: translateX(-50%);
    text-align: center;
  }
}

@media (min-width: 700px) {
  
  .content {
    position: absolute; 
    left: 14%; 
    bottom: 25%;
    margin-right: 100px;
  }
}

@media (min-width: 700px) and (min-height: 470px) {
  .trustWidget {
    visibility: visible; 
  }
}

@media (min-width: 700px) and (max-height: 470px) {
  .trustWidget {
    visibility: collapse; 
  }
}

@media (max-width: 700px) and (min-height: 470px) {
  .trustWidget {
    visibility: collapse; 
  }
}

@media (max-width: 700px) and (max-height: 470px) {
  .trustWidget {
    visibility: collapse; 
  }

  .linkBar {
    visibility: collapse; 
  }
}